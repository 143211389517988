<template>
  <div id="map">
  </div>
</template>

<style lang="sass">
  @import '../../node_modules/leaflet/dist/leaflet.css'
</style>

<style>
#map {
  height: 100%;
  background-color: #dae4f7;
}
</style>


<script>
import L from 'leaflet'

var map;
const tileMaps = {
  "ESRI": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri'
  })
};

const layers = {
};

export default {
  name: 'Map',

  data () {
    return {
    };
  },

  mounted () {
    map = L.map('map', {maxZoom: 22});

    tileMaps.ESRI.addTo(map);
    const layerControl = L.control.layers(tileMaps, layers).addTo(map);
    const scaleControl = L.control.scale().addTo(map);

    map.setView([0, 0], 3);
  }
}
</script>
