<template>
<v-app-bar
	app
	color="primary"
	dark
>
	<div class="d-flex align-center">
	<v-img
		alt="Geocarnet Logo"
		class="shrink mr-2"
		contain
		src="https://celinebarrier.fr/images/cbarrier.svg"
		transition="scale-transition"
		width="40"
	/>

	<v-toolbar-title class="mx-2" @click="$router.push('/')" style="cursor: pointer;">Geocarnet</v-toolbar-title>

	</div>

	<v-spacer></v-spacer>

</v-app-bar>
</template>

<script>
//import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GCNavigation',
  data() {
    return {
      drawer: false,
    };
  },

  methods: {
  }

};
</script>
