<template>
<v-footer app>

	<v-spacer></v-spacer>

	<small class="d-none d-sm-inline">&copy; {{year}} <a href="https://celinebarrier.fr/" target="_blank" class="brand">Marmotte</a></small>

	<v-spacer></v-spacer>

</v-footer>
</template>

<style>
</style>

<script>
export default {
  name: 'GCFooter',

  computed: {
    year () {
      const date = new Date();
      return date.getUTCFullYear();
    }
  }
};
</script>
