<template>
  <v-app id="gc">
    <gc-navigation></gc-navigation>
    <v-main>
        <router-view></router-view>
    </v-main>
    <gc-footer></gc-footer>
    <v-snackbar v-model="snack"
      :color="snackColour"
      :timeout="6000"
    >
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import GcNavigation from './components/navigation';
import GcFooter from './components/footer';

export default {
  name: 'Geocarnet',

  components: {
    GcNavigation,
    GcFooter
  },

  data: () => ({
    snack: false
  }),


  computed: {
    snackText () { return this.$store.state.snack.snackText },
    snackColour () { return this.$store.state.snack.snackColour }
  }
};
</script>
